import React, { Suspense, lazy} from "react";
import {BrowserRouter} from 'react-router-dom'
import Preloader from "./components/preloader/preloader";
import "./assets/fonts/stylesheet.css"
import 'swiper/swiper.scss';
import 'mapbox-gl/dist/mapbox-gl.css'

const AppRouter = lazy(() => import("./components/AppRouter"))

const App = () => {
    return (
        <Suspense fallback={<Preloader/>}>
            <BrowserRouter>
                <AppRouter/>
            </BrowserRouter>
        </Suspense>
    );
};

export default App;

