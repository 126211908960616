import React from 'react'
import './preloader.scss'

import PreloaderImage from '../../assets/img/preloader.png'

const Preloader = () => {
    return (
        <div className="preloader__wrapper">
            <img src={PreloaderImage} className="preloader__image" alt="прелоадер"/>
        </div>
    )
}

export default Preloader